import { User } from "firebase/auth";
import { Timestamp } from "./components/admin-users";

export interface UserPurchases {
  [name: string]: { expiration: Timestamp } | string;
}

export interface UserQuestionAnswer {
  name: string;
  fromTest: string | null;
  testId: number;
  questionId: number;
  answer: string;
  isCorrect: boolean;
  flagged: boolean;
  highlighted: boolean;
  seen: boolean;
}

export interface UserTest {
  testId: number;
  timestamp: number;
  correctPct: number;
  correctAnswers: number;
  wrongPct: number;
  wrongAnswers: number;
  questions: UserQuestionAnswer[];
  questionsTotal: number;
  questionsAnswered: number;
  secondsRemaining: number;
}

export interface Detail {
  startQuestion: number
  endQuestion: number
  title: string
  details: string
  testId: number
}

export interface Test {
  name: string
  coverPage: string
  references: string
  description: string
  categoryName: string
  summary: string
  id: number
  questions: Question[]
  details: Detail[]
  isHidden: boolean
}

export interface PdfInfo {
  file: string,
  title: string,
}
export interface CategoryVideos {
  title: string,
  videos: VideoData[],
}
export interface Videos {
  [moduleName: string]: CategoryVideos[];
}

export interface StudyGuideData {
  name: string;
  source: string;
}
export interface ModuleStudyGuides {
  [moduleName: string]: StudyGuideData[]
}
export interface VideoData {
  link: string;
  id: string;
  title: string;
  pdfs?: PdfInfo[],
}
export interface SeenVideo {
  id: string;
  seen: boolean;
  timestamp: number;
}

export interface UserModuleTestInfo {
  [module: string]: {
    tests: UserTest[];
    videos: SeenVideo[];
  };
}

export interface Flashcard {
  q: string;
  a: string;
  t: string;
  id: number
}
export interface Flashcards {
  flashcards: Flashcard[];
}

export interface Answer {
  name: string;
  description: string;
}

export interface ShuffledAnswers {
  [questionId: number]: Answer[]
}
export interface Question {
  name: string
  description: string;
  answer: string;
  reason: string;
  citation: string;
  fromTest: string | null;
  answers: Answer[];
  id: number;
  testId: number;
  cbpCategory: string | null;
  cbpCategoryName: string | null;
  hide: boolean;
}
export interface TestsFlashcards {
  tests: {
    chapter: Test[]
    fullYear: Test[]
  };
  flashcards: Flashcard[];
}
export interface ModuleData {
  [module: string]: TestsFlashcards;
}
export interface Chapter {
  id: number;
  cat: number;
  name: string;
}
export interface Category {
  id: number;
  name: string;
  randNum: number;
  chapters: Chapter[];
}
export interface Module {
  tag: string; // purchase code
  name: string;
  categories: Category[];
}
export interface ModuleTitles {
  [module: string]: string;
}
export interface Modules {
  [module: string]: Module;
}
export interface LoggedInUser {
  uid: string;
  additionalUserInfo?: {
    providerId: string;
    isNewUser: false;
  };
  credential?: any;
  operationType?: string;
  user: User; 
}

export enum ActionType {
  init = "INIT",
  updateTest = "UPDATE_TEST",
  updateFlashcards = "UPDATE_FLASHCARDS",
  addTest = "ADD_TEST",
  addVideo = "ADD_VIDEO",
  update = "UPDATE",
  deleteTest = "DELETE_TEST",
  deleteVideo = "DELETE_VIDEO",
}

export enum CbprepSku {
  SKU103 = 'SKU103', // everything online - videos, FC, test center
  SKU104 = 'SKU104', // everything online - videos, FC, test center
  SKU105 = 'SKU105', // only test center
  SKU107 = 'SKU107', // only FC
  SKU109 = 'SKU109'  // tests and flashcards
}

export enum UserActionType {
  SET_USER = "SET_USER",
}
export interface Action {
  type: ActionType;
}

export interface ModuleTestAction extends Action {
  payload: { moduleName: string; test: Question[] };
}

export interface ModuleFlashcardAction extends Action {
  payload: { moduleName: string; flashcards: Flashcard[] };
}

export interface ModuleDataAction extends Action {
  payload: { moduleData: ModuleData };
}

export interface UserInitLoadAction extends Action {
  payload: { userModuleTestInfo: UserModuleTestInfo };
}
export interface UserAddTestResultAction extends Action {
  payload: { moduleName: string; test: UserTest };
}
export interface UserAddVideoResultAction extends Action {
  payload: { moduleName: string; video: SeenVideo };
}
export interface UserDeleteTestResultAction extends Action {
  payload: { moduleName: string; testId: number };
}
export interface UserDeleteVideoResultAction extends Action {
  payload: { moduleName: string; id: string };
}
export interface IUserAction {
  type: ActionType;
  payload: object;
}

export interface TestContextInterface {
  moduleTests: ModuleData;
  setTestModules: Function;
  updateTestFlashcardsModule: Function;
  loadTestsFlashcards: Function
}

export interface UserAccess {
  module: string;
  expiration: Date;
}

export interface UserContextInterface {
  userModuleTestInfo: UserModuleTestInfo;
  getUserTestData: Function;
  clearTestResult: Function;
  addUserTest: Function;
  addUserVideo: Function,
  removeUserVideo: Function,
  userError: string,
}

export interface AccountContextInterface {
  userModuleTestInfo: UserModuleTestInfo;
  getUserTestData: (userId: string, callback: (error: string, data: undefined | UserModuleTestInfo) => void) => void;
  userId: string;
  loaded: boolean;
  videoCount: number;
}
export interface StoreConextInterface {
  loginUser: (email: string, password: string) => void;
  logoutUser: () => void;
  loggedInUser?: LoggedInUser | undefined;
  loginError?: string;
  isLoading: boolean;
  modules: string[];
  activeModule: string | null;
  changeActiveModule: Function;
  isAdmin: boolean;
  appError: string;
  appInfo: string;
  userTestData: UserTest[];
  userVideoData: SeenVideo[];
  videos: CategoryVideos[];
  testsFlashcards: TestsFlashcards;
  removeTestResult: Function;
  addTestResult: Function;
  addVideo: Function;
  removeVideo: Function;
  sendPasswordResetEmail: Function,
  deleteUserData: Function,
  dissmissAppInfo: Function,
  updateTestAndFlashcards: Function,
  setError: Function,
  access: string,
  userExpiration: Timestamp,
}
