import React from "react"
import styled from "styled-components"
import { UserQuestionAnswer } from "../types"
import { TakeTestCompass } from "./take-test-compass"
import { deviceSize } from "./common";

const StyledTestFooter = styled.div`
width: 100%;

position: fixed;
bottom: 0;

color: #fff;
background-color: #016BAC;

display: flex;
flex-direction: row;
justify-content: space-between;

>div:nth-child(1){
  padding: 0.25rem 0.5rem;
  
  a{
    color: white;
    font-weight: 400;
    padding-right: 0.25rem;
    text-decoration: none;
    
    &:hover{
      text-decoration: underline;
    }
  }
}


>div:nth-child(2){
  display:flex;
  flex-direction: row;
  
  >button{
    border: none;
    border-left: 1px solid white;
    border-right: 1px solid white;
    padding: 0.25rem 0.5rem;
    background-color: #016BAC;
    color: #fff;

    &:hover{
      background-color: #81AEE1;
      cursor: pointer;
      &:disabled{
      background-color: grey;
      cursor: inherit;
      }
    }

    &:disabled{
      background-color: grey;
    }

    &:focus{
      outline: none;
    }

  }
}
@media ${deviceSize.mobileL} {
    flex-wrap: wrap;
  }
`
interface testFooterPropsInterface {
  updateIndex: Function
  index: number
  setShowAnswer: Function
  showAnswer: boolean
  setShowResults: Function
  showResults: boolean
  setShowCompass: Function
  showCompass: boolean
  goToQuestion: Function
  maxQuestions: number
  questions: UserQuestionAnswer[]
}

export const TestFooter = (props: testFooterPropsInterface) => {

  return (
    <StyledTestFooter>
      <div>

      </div>
      <div>
        <button
          disabled={props.index === 0}
          onClick={() => props.updateIndex(props.index - 1)}
        >Previous</button>

        <button
          onClick={() => props.setShowAnswer(!props.showAnswer)}
        >
          {props.showAnswer ? 'Hide' : 'Show'} Answer
        </button>

        <button
          onClick={() => props.setShowResults(!props.showResults)}
        >
          {props.showResults ? 'Hide' : 'Show'} Test Results
        </button>

        <button
          onClick={() => props.setShowCompass(!props.showCompass)}
        >
          {props.showCompass ? 'Hide' : 'Show'} Compass
        </button>


      </div>
      <TakeTestCompass 
        goToQuestion={props.goToQuestion} 
        showCompass={props.showCompass} 
        questions={props.questions} 
      />
    </StyledTestFooter>
  )
}
