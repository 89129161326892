import React from 'react'
import styled from 'styled-components'

const StyledTestInfo = styled.div`
`

export const TestInfo = () => {
  return (
    <StyledTestInfo>
      <b>
       Announcements and stuff you should know
      </b>
       <p>        
        <b>9.12.24
      </b>  
      </p>
      <p>Registration for the October exam is over. If you registered, you will receive an email from
        the testing company to select your test location.  I do not know exactly when this email will be
        sent, but the CBP website indicates sometime around the first week of October. Please review the
        CBP regsitration info page!  PLEASE!!!   <a href="https://www.cbp.gov/trade/programs-administration/customs-brokers/license-examination-notice-examination/cble-registration-information">click here</a>
        There is information on what happens after you register and when... etc... please review!
        </p>
         <p>
         <b>7.24.24
      </b>  
      </p>
      <p>CBP announced that  (1) the 2024 BASIC HTS will be used for the October CBLE.; (2) The 2023 CFR
        will be used for the October CBLE.; (3) CBP changed one of the ACE DOCS.  The ACE Business Rules
        and Process Document Version 12 will be used for the October CBLE. Please replace that document.
        <a href={  require(`../assets/extra/aceupdate724.pdf`) }>Click here for the update</a>  
        Download and print double sided.  Replace in the ACE / DIRECTIVE Section of study guide.
        You do NOT need to update if you joined me on July 21st or later - your study guide has this new
        ACE Document.  Of course contact me if you have any questions.  FYI, Our materials are in good shape.  
      </p>
      <p>        
        <b>6.10.24
      </b>  
      </p>
      <p>Hello to all.  I need you to listen up and pay attention!!!  This spring I decided to give AD / CV
        its own chapter.  So, since April 27th, If you got a study guide,  AD / CV / Evasion was moved
        to Ch 18.  Chapters 17 and 18 were combined into Ch 17.  Most of you have this updated book.
        However, if you are a student who joined before April 27th...  I want to get you an updated
        text.  Too many changes to ask you to update.  So, if you joined me before April 27th, email me with
        a good shipping address and I am going to ship you the updated TEXT part of your study guide!  Need 
        help with dates...  just email me and we will get the update figured out!
        </p>
       <p>        
        <b>Next update issue to be aware of for everyone who joined me from April 27th to May 30th
      </b>  
      </p>
      If you joined me between April 27th and May 30th, here are the updates for your study guide.
      These changes are the updates from the last exam, and as necessary for other issues.  Please
      download and update your study guide.  <a href={  require(`../assets/extra/update5.27.24.pdf`) }>Click here for the update</a>.
      If you need any help, call me or email.
      <p>
        </p>
       <p>        
        <b>
      </b>  
      </p>
      <p>
        </p>
      <p>        
        <b>
      </b>  
      </p>
      <p>
      </p> 
       <p>        
        <b>Reserved space for future super important info
      </b>  
      </p> 
      <p>
      </p>
       <p>        
        <b>
      </b>  
      </p> 
      <p>        
      </p>
      <p>        
        <b>reserved space for future super important info
      </b>  
      </p> 
      <p>
      <b>
        01.1.24
      </b>  
      </p>
      <p>
        I am always a little worried about too much information.  Occasionally, students contact me about legal cites
        in past exam questions. (19 USC 1592) YOU DO NOT need to know anything about the United States Code.  
        Here is a cheat sheet of cites from past exams.  Just keep it with your study guide in case it is needed.  <a href={  require(`../assets/extra/USC.pdf`) }>Click here for the USC Document</a>
      </p>
      <p>
      </p>
    </StyledTestInfo>
  )
}
