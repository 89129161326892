import React from "react"
import styled from "styled-components"
import { ANSWER_LETTERS } from "../constants"
import { Question, Test, UserQuestionAnswer, ShuffledAnswers } from "../types"
import { SquareButton, infoBackgroundColor, RoundButton } from "./common"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledAnswerLine = styled.div`
  display: flex;
  padding: 0.2rem;
  border-radius: 15px;
  
  &.correct {
    background-color: #85ff85;
  }
  
  &.wrong {
    background-color: #ff7a7a;
  }
  
  > button {
    min-width: 50px;
    max-height: 20px;
  }
  
  > span {
    padding-left: 1rem;
  }
`;

const StyledShowAnswerContainer = styled.div`
display: flex;
justify-content: center;
  margin-top: 1rem;
  border-radius: 15px;
  padding: 1rem;
  background-color: ${infoBackgroundColor};

`

const StyledTakeTestQuestion = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 124px;

  .question-img-container{
    margin-right: .5rem;
    display: inline-block;
    
    img{
      width: 100%;
      max-width: 100%;
      height: auto; 
    }
  }

  >div{
    flex: 1;
    background-color: white;
    padding: 1rem;
    max-width: calc(100% - 18rem);
    
    &.question-text-container{
      div{

        &.question-general-info{
          margin-bottom: .5rem;
        }
      }
    }
  }
`

interface TakeTestQuestionProps {
  test: Test
  currentQuestion: Question
  currentUserTestAnswerObject: UserQuestionAnswer
  shuffledAnswers: ShuffledAnswers
  answerQuestion: Function
  index: number
  showAnswer: boolean
  updateIndex: Function
  maxQuestions: number
}

const QuestionHeader = ({ q, t, m }: { q: Question, t: Test, m: number }) => {
  const isFullExam = !t.categoryName
  const testName = t.categoryName ? q.fromTest : '';
  const categoryInfo = q.cbpCategoryName ? `${q.cbpCategory} ${q.cbpCategoryName} - ` : '';

  const StyledTitle = styled.div`
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 2rem;
  `
  if (isFullExam) {
    return (
      <StyledTitle>
        <div>{categoryInfo}Question {q.name} of {m}</div>
      </StyledTitle>
    )
  }
  return (
    <StyledTitle>
      <div>{testName} {categoryInfo ? ` - ${categoryInfo}` : ' - '} Question {q.name}</div>
    </StyledTitle>
  )
}

const renderHTML = (htmlContent: string) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};


export const TakeTestQuestion = (props: TakeTestQuestionProps) => {
  const currentQuestion = props.currentQuestion
  const shuffledAnswers = props.shuffledAnswers
  const currentUserTestAnswerObject = props.currentUserTestAnswerObject
  const answerQuestion = props.answerQuestion
  const index = props.index
  const showAnswer = props.showAnswer

  const getDetails = (questionName: string) => {
    const questionId = parseInt(questionName)
    const testId = props.test.categoryName ? currentQuestion.testId : null;
    let img: any
    for (let i = 0; i < props.test.details.length; i++) {
      const detail = props.test.details[i];
      if (questionId >= detail.startQuestion && questionId <= detail.endQuestion && (detail.testId === testId || !testId)) {
        img = { title: detail.title, file: require(`../assets/data/tests/img/${detail.details}`) }
        break
      }
    }
    return img
  }

  const img = getDetails(currentQuestion.name)

  const lastQuestion = props.index === props.maxQuestions - 1;
  return (
    <StyledTakeTestQuestion>
      <div className="question-text-container">
        <div className='question-general-info'>
          <QuestionHeader q={currentQuestion} t={props.test} m={props.maxQuestions} />
          {img && <div>{renderHTML(img.title)}</div>}
          {img &&
            <div className='question-img-container'>
              <div><img alt={`img_${currentQuestion.id}`} src={img.file} /></div>
            </div>
          }
          {currentQuestion.description && <div>{
            renderHTML(currentQuestion.description)
          }</div>}
        </div>
        <div className='question-answers'>
          {shuffledAnswers[currentQuestion.id].map((a, i) => (
            <StyledAnswerLine
              key={a.name}
              className={`
    ${showAnswer && currentQuestion.answer === a.name ? 'correct' : ''}
    ${showAnswer && currentUserTestAnswerObject.answer !== currentQuestion.answer && currentUserTestAnswerObject.answer === a.name ? 'wrong' : ''}
  `.trim()}
            >
              <RoundButton
                selected={currentUserTestAnswerObject.answer === a.name}
                onClick={() => answerQuestion(index, a.name)}
              >
                {ANSWER_LETTERS[i]}
              </RoundButton>
              <span>{renderHTML(a.description)}</span>
            </StyledAnswerLine>
          ))}
        </div>
        {showAnswer &&
          <StyledShowAnswerContainer>
            {currentQuestion.reason && currentQuestion.reason !== '' &&
              <div>
                <b>Reason:</b> {renderHTML(currentQuestion.reason)}
              </div>
            }
            {currentQuestion.citation && currentQuestion.citation !== '' &&
              <div>
                <b>Citation:</b> {renderHTML(currentQuestion.citation)}
              </div>
            }
          </StyledShowAnswerContainer>
        }
      </div>
      {!lastQuestion &&
        <SquareButton disabled={lastQuestion}
          onClick={() => props.updateIndex(props.index + 1)}>
          {"Next Page"}{' '}
          <FontAwesomeIcon size="sm" icon={faChevronRight} />
          <FontAwesomeIcon size="sm" icon={faChevronRight} />
        </SquareButton>
      }
      {lastQuestion && <StyledShowAnswerContainer>Congratulations, end of the Test, Review your Answers</StyledShowAnswerContainer>}
    </StyledTakeTestQuestion>
  )
}