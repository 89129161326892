import React, { useContext, useState, useEffect } from "react";
import { StoreConextInterface, Question, UserTest, Answer, Test, UserQuestionAnswer, ShuffledAnswers } from "../types";
import { StoreContext } from "../contexts/storeContext";
import styled from "styled-components";
import { TestFooter } from "./take-test-footer";
import { TakeTestSummary } from "./take-test-summary";
import { TakeTestQuestion } from "./take-test-question";
import { SideTabs } from "./side-tabs";
import { TestProgress } from "./test-progress";
import { TestCountDownTimer } from "./test-countdown";
import { useLocation } from "react-router-dom";

const DEFAULT_TIME_LIMIT = (60 * 60 * 4) + 30 * 60; // 4 hours and 30 minutes
let shuffledAnswers: ShuffledAnswers = {}

const shuffleArray = (array: Answer[]) => {
  let newArray = array.slice()
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray.filter(x => x.description !== undefined && x.description.trim() !== '')
}

const StyledTakeTest = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: center;

  >div:nth-child(1){
    width: 100%;
    margin: 0.25rem 2rem;

  }
`;

const StyledTopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const StyledTestHeader = styled.div`
  margin: 0 1rem;
  font-size: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
`
interface TakeTestPassedProps {
  test: Test;
  userTest: UserTest
}

export const TakeTest = () => {
  const location = useLocation()
  const { test, userTest: userTestProp }: TakeTestPassedProps = location.state as TakeTestPassedProps
  const { addTestResult } = useContext<StoreConextInterface>(
    StoreContext,
  );
  const [questions, setQuestions] = useState<Question[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  // const [autoForeward, setAutoForeward] = useState<boolean>(false);
  const [showCompass, setShowCompass] = useState<boolean>(false);
  const [userTest, setUserTest] = useState<UserTest>({
    timestamp: new Date().getTime(),
    testId: 0,
    correctPct: 0,
    correctAnswers: 0,
    wrongPct: 0,
    wrongAnswers: 0,
    questions: [],
    questionsAnswered: 0,
    questionsTotal: questions.length,
    secondsRemaining: userTestProp?.secondsRemaining || DEFAULT_TIME_LIMIT,
  });

  const [secondsRemaining, setSecondsRemaining] = useState<number>(userTest?.secondsRemaining || DEFAULT_TIME_LIMIT )
  const [currentUserTestAnswerObject, setCurrentUserTestAnswerObject] = useState<UserQuestionAnswer>({
    name: '',
    fromTest: '',
    testId: 0,
    questionId: 0,
    answer: '',
    isCorrect: false,
    flagged: false,
    highlighted: false,
    seen: false,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const t = secondsRemaining - 1;
      setSecondsRemaining(t);
      if (userTestProp) userTestProp.secondsRemaining = t;
    }, 1000);
    return () => clearInterval(interval);
  }, [secondsRemaining]);

  useEffect(() => {
    setQuestions(test.questions.filter(q => !q.hide));
    let newSummary = !!userTestProp
      ? userTestProp
      : {
        ...userTest,
        testId: test.id,
        secondsRemaining: DEFAULT_TIME_LIMIT,
        questions: test.questions.filter(q => !q.hide).map(q => ({
          name: q.name,
          fromTest: q.fromTest,
          testId: q.testId,
          questionId: q.id,
          answer: "",
          isCorrect: false,
          flagged: false,
          highlighted: false,
          seen: false,
        })),
      }
    setUserTest(newSummary);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTestProp]);

  useEffect(() => {
    const indexQuestion = questions[index];
    const userTestAnswer = userTest.questions.find(q => q.questionId === indexQuestion.id);
    if (userTestAnswer) {
      if (!userTestAnswer.seen) userTestAnswer.seen = true
      setCurrentUserTestAnswerObject(userTestAnswer)
    }
  }, [index, questions, userTest])

  const updateIndex = (index: number) => {
    setIndex(index);
    setShowAnswer(false);
    setShowResults(false);
    const question = questions[index];
    const userValue = userTest.questions.find(q => q.questionId === question.id);
    if (userValue) {
      if (!userValue.seen) userValue.seen = true
      setCurrentUserTestAnswerObject(userValue)
    }
  };

  const gotoQuestion = (questionId: number) => {
    const index = questions.findIndex(q => q.id === questionId);
    updateIndex(index);
  };

  const answerQuestion = (index: number, answerValue: string) => {
    const question = questions[index];
    const userQuestionAnswer: UserQuestionAnswer = {
      name: currentUserTestAnswerObject.name,
      fromTest: currentUserTestAnswerObject.fromTest,
      testId: currentUserTestAnswerObject.testId,
      questionId: currentUserTestAnswerObject.questionId,
      answer: answerValue,
      isCorrect: answerValue === question.answer,
      flagged: currentUserTestAnswerObject.flagged,
      highlighted: currentUserTestAnswerObject.highlighted,
      seen: true
    };
    setCurrentUserTestAnswerObject(userQuestionAnswer)
    saveNewAnswer(userQuestionAnswer)
  };

  const saveNewAnswer = (userQuestionAnswer: UserQuestionAnswer) => {
    let userTestAnswerIndex = userTest.questions.findIndex(x => x.questionId === userQuestionAnswer.questionId)
    userTest.questions[userTestAnswerIndex] = userQuestionAnswer

    const correctWrong = userTest.questions.reduce(
      (p, q) => (q.isCorrect ? { ...p, c: p.c + 1 } : q.answer === "" ? { ...p, u: p.u + 1 } : { ...p, w: p.w + 1 }),
      {
        c: 0,
        w: 0,
        u: 0,
      },
    );

    userTest.correctAnswers = correctWrong.c;
    userTest.timestamp = new Date().getTime()
    userTest.correctPct = !!correctWrong.c ? Number(((correctWrong.c / questions.length) * 100).toFixed(1)) : 0;
    userTest.wrongAnswers = correctWrong.w;
    userTest.wrongPct = !!correctWrong.w ? Number(((correctWrong.w / questions.length) * 100).toFixed(1)) : 0;
    userTest.questionsTotal = questions.length;
    userTest.questionsAnswered = correctWrong.c + correctWrong.w;
    userTest.secondsRemaining = secondsRemaining;
    setUserTest(userTest);
    addTestResult(userTest);
    
  }


  const updateFlagged = () => {
    const userQuestionAnswer: UserQuestionAnswer = {
      name: currentUserTestAnswerObject.name,
      fromTest: currentUserTestAnswerObject.fromTest,
      testId: currentUserTestAnswerObject.testId,
      questionId: currentUserTestAnswerObject.questionId,
      answer: currentUserTestAnswerObject.answer,
      isCorrect: currentUserTestAnswerObject.isCorrect,
      flagged: !currentUserTestAnswerObject.flagged,
      highlighted: currentUserTestAnswerObject.highlighted,
      seen: true
    };
    setCurrentUserTestAnswerObject(userQuestionAnswer)
    saveNewAnswer(userQuestionAnswer)
  }

  if (index !== undefined && questions[index] !== undefined && shuffledAnswers[questions[index].id] === undefined) {
    shuffledAnswers[questions[index].id] = shuffleArray(questions[index].answers)
  }

  if (!questions[index]) return <></>
  const currentQuestion = questions[index]

  return (
    <StyledTakeTest flagged={currentUserTestAnswerObject.flagged}>
      <div>
        <SideTabs flagged={currentUserTestAnswerObject?.flagged} updateFlagged={updateFlagged} />
        <div>
          <StyledTopBar>
          <TestProgress maxQuestions={questions.length} currentQuestionIndex={index} />
          {!test.categoryName && <TestCountDownTimer totalSeconds={secondsRemaining} />}
          </StyledTopBar>
          <div>
            <StyledTestHeader>
              {test.categoryName ? `Category Based Test, ${test.categoryName}` : `${test.name} Test`}
            </StyledTestHeader>
          </div>
          {showResults ?
            <TakeTestSummary
              userTest={userTest}
              gotoQuestion={gotoQuestion}
            />
            :
            <TakeTestQuestion
              test={test}
              currentQuestion={currentQuestion}
              currentUserTestAnswerObject={currentUserTestAnswerObject}
              shuffledAnswers={shuffledAnswers}
              answerQuestion={answerQuestion}
              index={index}
              showAnswer={showAnswer}
              updateIndex={updateIndex}
              maxQuestions={questions.length}
            />
          }
        </div>
      </div>
      <TestFooter
        index={index}
        updateIndex={updateIndex}
        setShowAnswer={setShowAnswer}
        showAnswer={showAnswer}
        setShowResults={setShowResults}
        showResults={showResults}
        setShowCompass={setShowCompass}
        showCompass={showCompass}
        goToQuestion={gotoQuestion}
        maxQuestions={questions.length}
        questions={userTest.questions}
      />
    </StyledTakeTest>
  );
};
